<template>
  <div class="buyOrderParticulars">
        <van-nav-bar
            title="买料详情"
            left-arrow
            fixed
            safe-area-inset-top
            placeholder
            @click-left="leftClick"
        />

        <!-- 审核进度    待审核-->
        <div class="reviewProgress  ToBeReviewed" v-if="orderList.status == 'SUSPENSE'">
        </div>
        <!-- 审核进度  未通过  取料审核中   待取料-->
        <div class="reviewProgress  FailAndReclaiming" v-if="orderList.status == 'WAIT_FETCH_AUDIT' || orderList.status == 'WAIT_FETCH' || orderList.status == 'FAIL'">

        </div>
        <!-- 审核进度  取料中-->
        <div class="reviewProgress reclaiming"  v-if="orderList.status == 'WAIT_FETCH_FINISHED'">
        </div>
        <!-- 审核进度  已完成-->
        <div class="reviewProgress  Completed" v-if="orderList.status == 'DONE'">
        </div>

        <!-- 订单信息 -->
        <div class="orderInfo">
            <div class="orderTop title">
                <h3> 订单信息</h3>
                <div class="commonBorder ashBorder" :class="{
                        greenBorder: orderList.status == 'DONE' ||orderList.status =='BUY_VIOLATE'?true:false,
                        yellowBorder:orderList.status == 'WAIT_PAYED' || orderList.status == 'WAIT_FETCH' || orderList.status == 'WAIT_FETCH_AUDIT' ||orderList.status =='WAIT_FETCH_FINISHED'?true:false
                        }">{{
                    orderList.status == 'WAIT_FETCH_AUDIT'?'取料审核中...'
                    :orderList.status == 'WAIT_FETCH'?'待取料'
                    :orderList.status == 'FAIL'?'未通过'
                    :orderList.status == 'SUSPENSE'?'待审核'
                    :orderList.status == 'WAIT_FETCH_FINISHED'?'取料中'
                    :orderList.status == 'WAIT_PAYED'?'待付款'
                    :orderList.status == 'UNDELIVERED'?'未交付'
                    :orderList.status == 'RECEIVE'?'已交付'
                    :orderList.status == 'CANCEL'?'已取消'
                    : '已完成'}}</div>
            </div>
            <div class="info">
                                    <!-- 下单时间 -->
                    <div class="flex">
                        <div class="leftText">下单时间</div>
                        <div class="rightText">{{orderList.createTime | formatDate_}}</div>
                    </div>
                                    <!-- 订单编号 -->
                    <div class="flex">
                        <div class="leftText">订单编号</div>
                        <div class="rightText">{{orderList.oid}}</div>
                    </div>
                    <!-- <div v-if="Array.isArray(orderList.goods)&&orderList.goods.length!=0"> -->
                                    <!-- 货物分类 -->
                    <div class="flex">
                        <div class="leftText">货物分类</div>
                        <div class="rightText">{{orderList.goods ? orderList.goods[0].name : ''}}</div>
                    </div>
                                    <!-- 单价 -->
                    <div class="flex">
                        <div class="leftText">单价(元/克)</div>
                        <div class="rightText">{{orderList.goods ? orderList.goods[0].price : ''}}</div>
                    </div>
                                    <!-- 重量 -->
                    <div class="flex">
                        <div class="leftText">重量(克)</div>
                        <div class="rightText">{{orderList.goods ? orderList.goods[0].weight.toFixed(2) : ''}}</div>
                    </div>
                                    <!-- 押金比例 -->
                    <div class="flex">
                        <div class="leftText">押金比例(%)</div>
                        <div class="rightText">{{ show && orderList.businessType !== '0' ? depositProportion:'-'}}</div>
                    </div>
                    <div class="flex">
                      <div class="leftText">保管费</div>
                      <div class="rightText">{{ orderList.storageFee ? orderList.storageFee : 0}}</div>
                    </div>
                                    <!-- 金额 -->
                    <div class="flex">
                        <div class="leftText">金额(元)</div>
                        <div class="rightText">{{orderList.storageFee  ? orderList.goods[0].total + orderList.storageFee : orderList.goods ? orderList.goods[0].total : ''}}</div>
                    </div>
                    </div>
            <!-- </div> -->
        </div>

        <!-- 收款账号 -->
        <div class="getMoney" v-if="orderList.status == 'FAIL' ||orderList.status == 'DONE' || orderList.businessType != '0' ?false:true">
            <div class="title">
                <h3>   {{GLOBAL}}收款账号</h3>
                <div class="copy" @click="copy(banklist.num)">
                    <div class="icon"></div>
                    复制</div>
            </div>
            <div class="info">
                        <!-- 银行图标 -->
                <div class="bankIcon moneyflex">
                    <!-- <img v-if="banklist.id == 10" :src="banklist.bankCard.logo" class="Img" alt="">
                    <div v-if="!banklist.id == 10" class="icon"></div> -->
                        <div class="leftText">收款银行</div>
                    <div class="bank">{{banklist.bank}}</div>
                </div>
               <div class="moneyflex">
                        <div class="leftText">银行卡号</div>
                        <div class="rightText">{{banklist.num}}</div>
                </div>
                <div class="moneyflex">
                        <div class="leftText">开户支行</div>
                        <div class="rightText">{{banklist.address}}</div>
                </div>
                <div class="moneyflex">
                        <div class="leftText">持卡人</div>
                        <div class="rightText">{{banklist.name}}</div>
                </div>
            </div>
        </div>
        <!-- 结算单 -->
        <!-- <div class="settlementSheet" v-if="orderList.status =='DONE'||orderList.status =='WAIT_FETCH_AUDIT' || (orderList.businessType == '1' &&orderList.status !='UNDELIVERED')">   -->
            <div class="settlementSheet" v-if="orderList.status =='BUY_VIOLATE'||orderList.status =='DONE' ||orderList.status =='WAIT_FETCH_AUDIT' || orderList.status == 'WAIT_PAYED'">
                <h3 class="jieSuan">结算单</h3>
                <div class="xiangQing" @click="ToPurchaseSettlement">
                    详情>>
<!--                    <div class="rightIcon"></div>-->
                </div>
        </div>
    <footer v-if="isDefault">
      <div class="left" @click="ceringModel = true">提料</div>
      <div class="right" @click="toBuyDefault">无法提料</div>
    </footer>
    <van-dialog v-model="ceringModel">
            <div class="title">提示</div>
      <img src="@/assets/images/kefu_as.png" alt="" style="margin-top: 20px">
      <div class="centerbox_dialog">请咨询客服电话18126327787进行操作</div>
      <div class="gocer" @click="ceringModel = false"></div>
    </van-dialog>
  </div>
</template>

<script>
import *as api from'@/utils/api'
export default {
        data() {
            return {
                ceringModel:false,
                // 系统银行卡信息
                banklist:[],
                // 订单信息
                orderList:[],
                // 订单编号
                order:this.$route.query.order,
                isDefault:false,
                depositProportion:'',
                client:{},
                // 所有商品数据
                allpro:[],
                // 首次加载的数据
                initDatalist: [],
                // 是否开盘
                trade_: false,
                // 提交时间
                postTime:'',
                pricehj:0,
                priceboj:0,
                pricebaj:0,
                pricebaiy:0,
                initPriceName:{
                    huangjin:'huangjin9999',
                    baiyin:'baiyin9999',
                    bajin:"bajin9996",
                    bojin:"bojin9996"
                },
                policyDeposit:'', //押金政策
                show:false, // 是否显示押金比例
                miniOrderPolicy: 30, // 小克重下单押金策略
            }
        },
        watch:{
          allpro:function(){
              this.updatearr(this.initPriceName)
            if(this.orderList.goods && this.orderList.goods[0]) {
              // 获取押金比例  占用押金+物料差价 / 重量  / 押金政策 * 100
              const nowPrice = this.orderList.goods[0].goodsType === 'baiyin' ? this.pricebaiy :
                this.orderList.goods[0].goodsType === 'bojin' ? this.priceboj :
                  this.orderList.goods[0].goodsType === 'bajin' ? this.pricebaj :this.pricehj
              let difference = (( nowPrice - this.orderList.goods[0].price)*this.orderList.weight).toFixed(2)
              this.depositProportion = this.policyDeposit === 0 ? 100 : (((+this.orderList.earnestMoney + +difference - this.orderList.storageFee) / +this.orderList.weight) / +this.policyDeposit *100).toFixed(2)
            }

            }
      },
    methods:{
            toBuyDefault(){
                    let item = this.orderList
                 if (item.businessType != '0' &&  (item.status == "UNDELIVERED" || item.status == "WAIT_FETCH") ) {
                         this.$router.push({
                                name:'breakContract',
                                query:{active:'buy', activeName: item.goods[0].goodsType}
                            })
                 }

            },
            // 跳转结算详情页面
            ToPurchaseSettlement(){
                if (this.orderList.businessType != '0') {
                    this.$router.push({
                        name:'default',
                            query:{orderid:this.orderList.openId,
                            payType:'6',
                            type:'buy'
                        }
                    })
                }else{
                    this.$router.push({
                        name:'purchaseSettlement',
                        query:{openId:this.orderList.openId}
                    })
                }

            },
              // 封装获取系统银行卡的信息
            async getBankList(){
                let res = await api.banklist()
                // 获取id为10的银行类型
                res.forEach((item)=>{

                        this.banklist = item.bankCard


                })

            },
              // 顶部左侧按钮点击事件
            leftClick(){
                this.$router.back()
            },
            //  复制功能
            copy(txt){
                this.$copyText(txt).then(()=>{
                this.$toast('卡号已复制到剪贴板')
            })
            },
            unzip(b64Data) {
      let strData = atob(b64Data);
      const charData = strData.split("").map(function(x) {
        return x.charCodeAt(0);
      });
      const binData = new Uint8Array(charData);
      const data = pako.inflate(binData);
      strData = String.fromCharCode.apply(null, new Uint16Array(data));
      return decodeURIComponent(escape(strData));
            },
            onConnected() {
                const topic = "/price/all";
                this.client.subscribe(topic, this.responseCallback, this.onFailed);
            },
            connect() {
               this.client = Stomp.over(new WebSocket(process.env.VUE_APP_WS));
              // ---初始化mqtt客户端，并连接mqtt服务
              const headers = {
                login: "username",
                passcode: "password"
              };
              this.client.connect(headers, this.onConnected, this.onFailed);
              this.client.debug = null;
            },
            onFailed(frame) {
            //   console.log("错误: " + frame);
                  if (!this.client.connected) {
                setTimeout(() => {
                  this.connect();
                }, 1000);
              }
            },
            deepMerge(...objs) {
              const than = this
              const result = Object.create(null)
              objs.forEach(obj => {
                if (obj) {
                  Object.keys(obj).forEach(key => {
                    const val = obj[key]
                    if (than.isPlainObject(val)) {
                      // 递归
                      if (than.isPlainObject(result[key])) {
                        result[key] = than.deepMerge(result[key], val)
                      } else {
                        result[key] = than.deepMerge(val)
                      }
                    } else {
                        //  数组也要重新赋值  不然依然会引用到其他的
                      if (Array.isArray(val)) {
                        result[key] = [...val]
                      } else {
                        result[key] = val
                      }
                    }
                  })
                }
      })
      return result
            },
            isPlainObject(val) {
              const toString = Object.prototype.toString
              return toString.call(val) === '[object Object]'
            },
            responseCallback (frame) {
              this.allpro = []
              const resbody = eval('['+this.unzip(frame.body)+']')[0]
              const parse_res = JSON.parse(JSON.stringify(resbody));
              this.trade_ = resbody.trade;
              this.postTime = resbody.time
              // console.log('postTime1',this.postTime);
              if(this.firstloadData){
                this.initDatalist = resbody
                this.firstloadData = false
                for(const i in this.initDatalist){
                  if(this.initDatalist[i]['prices'] != undefined){

                    this.allpro.push(this.initDatalist[i]['prices'])
                  }
                }
              }else{
                this.initDatalist = this.deepMerge(this.initDatalist,parse_res)
                for(const i in this.initDatalist){
                  if(this.initDatalist[i]['prices'] != undefined){
                    this.allpro.push(this.initDatalist[i]['prices'])
                  }
                }
      }
            },
            // 价格变动更新视图
            pricenew(obj){
              this.allpro.find((x)=>{
                if(x[obj.huangjin]){
                  this.pricehj = x[obj.huangjin]['huigou']
                }
                if(x[obj.baiyin]){
                  this.pricebaiy = x[obj.baiyin]['huigou']
                }
                if(x[obj.bajin]){
                  this.pricebaj = x[obj.bajin]['huigou']
                }
                if(x[obj.bojin]){
                  this.priceboj = x[obj.bojin]['huigou']
                }
              })
            },
            // 更新数组
            updatearr(obj){
                    this.pricenew(obj)
            },

        },
        destroyed() {
            clearTimeout(this.setInterval)
            this.client.disconnect(function() {
              // 断开连接
              // console.log("See you next time!");
            })
        },
async   mounted(){
            //调用方法获取系统银行卡的信息
            this.getBankList()
  // 获取小克重订单押金策略
  api.getMiniOrderPolicy().then(res=>{
    this.miniOrderPolicy = res.buy
  })
            let res = await  api.getbuygold(this.order)
            this.orderList = res
            // console.log('orderList',this.orderList);

            let user=  JSON.parse(localStorage.getItem('userinfo'))
            // 政策押金 (买)
            // this.policyDeposit  = new Date(res.createTime*1000) > new Date("2022-11-26 12:00:00".replace(/-/g,'/')) ? user.depositDetail.curSell.policy : 10
            this.policyDeposit = res.businessType==='3' ? this.miniOrderPolicy : res.goods[0].goodsType === 'baiyin' ? user.depositDetail.curBuy.agPolicy :
              res.goods[0].goodsType === 'bojin' ? user.depositDetail.curBuy.ptPolicy :
                res.goods[0].goodsType === 'bajin' ? user.depositDetail.curBuy.pdPolicy : user.depositDetail.curBuy.policy
            // this.policyDeposit  = user.depositDetail.curSell.policy
            if (res.businessType != '0' &&  (res.status == "UNDELIVERED" ||  res.status == "WAIT_FETCH")) {
                        this.isDefault = true
            }

            switch(this.orderList.status)
            {
                case 'DONE' :
                     this.show = false
                     break;
                case 'CANCEL' :
                     this.show = false
                     break;
                 case 'FAIL':
                     this.show = false
                     break;
                case  'BUY_VIOLATE':
                     this.show = false
                     break;
                case  'RECEIVE':
                     this.show = false
                     break;
                 default:
                     // 押金比例
                    // 占用押金+物料差价 / 重量  / 押金政策 * 100
                        this.show = true
                     break;
            }

        },
        created(){
            this.connect()
        }
}
</script>

<style lang="scss" scoped>
::v-deep{
     .van-nav-bar{
        height: 100px;
        .van-nav-bar__content{
        line-height: 100px;
        height: 100px;
          background: $YellowHeadBackgroundColor;
        .van-nav-bar__title{
            font-size: 40px;
          color: $YellowHeadColor;
        }
        .van-icon{
            font-size: 40px;
            color: $YellowHeadColor;
        }
        }
     }
  .van-dialog__content{
    text-align: center;
  }
}
.buyOrderParticulars{
    background-color: #f5f5f5;
    height: 100%;
    width: 100%;
    padding:30px;
    .reviewProgress{
        width: 100%;
        height: 132px;
        background-color: #fff;
        padding:20px 80px 0 80px;
        .progress{
            width: 100%;
            .icons{
                width: 100%;
                display: flex;
                .iconText{
                    width: 200px;
                    height: 100%;
                    .icon{
                         width: 68px;
                         height: 64px;
                         margin-left: 20px;

                    }
                    .bottomText{
                        text-align: center ;
                    }
                }
            .iconLine{
                width:80px;
                height: 14px;
                margin-top: 28px;
                    }
            .ilone{
                background: url("~@/assets/images/arrow 1@2x.png") no-repeat;
                background-size: 100% 100%;
            }
            .iltwo{
                background: url("~@/assets/images/arrow 2@2x.png") no-repeat;
                background-size: 100% 100%;
            }
            }
        }
    }
    .ToBeReviewed{
        background: $YellowBuyProcessOne;
        background-size: 100% 100%;
    }
    .FailAndReclaiming{
        background: $YellowBuyProcessTwo;
        background-size: 100% 100%;
    }
    .reclaiming{
        background: $YellowBuyProcessThree;
        background-size: 100% 100%;
    }
    .Completed{
        background: $YellowBuyProcessFour;
        background-size: 100% 100%;
    }
    .orderInfo{
            width: 100%;
            background-color: #fff;
            margin-top: 30px;
            padding: 20px 0;
            border-radius: 8px;
            .orderTop{
                padding: 0 28px 20px;
                display: flex;
                justify-content: space-between;
                .line{
                    width: 6px;
                    height: 100%;
                    background-color: #ffb606;
                    position: absolute;
                    top: 0;
                    left:-10px
                }
            }
            .info{
                padding: 20px 28px 0px;
            }
    }
    .getMoney{
            width: 100%;
            background-color: #fff;
            margin-top: 40px;
            padding: 20px 0 0px;
            border-radius: 8px;
            .info{
                padding: 20px 28px;
                .bankIcon{
                    width: 100%;
                    display: flex;
                    .Img{
                        width: 48px;
                        height: 48px;
                    }
                    .icon{
                        width: 48px;
                        height: 48px;
                        background: url("~@/assets/images/icon_shou@2x.png") no-repeat;
                        background-size: 100% 100%;
                    }
                    .bank{
                        line-height: 48px;
                        margin-left: 20px;
                        font-size: 28px;
                    }
                }
            }
    }
    .settlementSheet{
        width: 100%;
        background-color: #fff;
        padding: 20px 28px;
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        .jieSuan{
            font-size: 32px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #1A1A1A;
            display: flex;
        }
        .xiangQing{
            width: 100px;
            height: 32px;
            color: $YellowFontColor;
            font-size: 28px;
            display: flex;
            justify-content: space-between;
            position: relative;

            .rightIcon{
                position: absolute;
                bottom: -2px;
                right: 0;
                width: 40px;
                height: 32px;
                background: url("~@/assets/images/btn_sell_more@2x.png") no-repeat;
                background-size: 100% 100%;
            }
        }
    }
}
.flex{
    display: flex;
    justify-content: space-between;
    .leftText{
        font-size: 28px;
        font-weight: 400;
        color: #999999;
        line-height: 60px;
    }
    .rightText{
        font-size: 28px;
        font-weight: 400;
        color: #242424;
        line-height: 60px;
    }
}
.moneyflex{
     display: flex !important;
     justify-content: space-between;
     .leftText{
        font-size: 28px;
        font-weight: 400;
        color: #999999;
        line-height: 60px;
        text-align: left;
        margin-right:18px;
        width: 150px;
    }
    .rightText{
        font-size: 28px;
        font-weight: 400;
        color: #242424;
        line-height: 60px;
        text-align: left;
    }
}
.title{
                border-bottom: 1px solid #E9E9E9;
                padding: 0 28px 20px;
                display: flex;
                justify-content: space-between;
                .line{
                    width: 6px;
                    height: 100%;
                    background-color: #ffb606;
                    position: absolute;
                    top: 0;
                    left:-10px
                }
                h3{
                    font-size: 28px;
                    font-weight: 500;
                    color: #242424;
                    display: flex;
                    position: relative;
                }
}
.copy{
    color: #666666;
    font-size: 28px;
    .icon{
        width: 30px;
        height: 30px;
        position: relative;
        top: 5px;
        background: url('~@/assets/images/btn_settlement_copy@2x.png') no-repeat;
        background-size: 100% 100%;
    }
}
.buyDefault{
    width: 100%;
    text-align: center;
    font-size: 28px;
    position: absolute;
    bottom: 100px;
    left: 0;
    color: #999999;
}
::v-deep.van-dialog {
  width: 500px;
}
footer{
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100px;
  .left{
    width: 50%;
    background-color: #f6d0ab;
    font-size: 32px;
    text-align: center;
    line-height: 100px;
    color: #39394D;
    div{
      line-height: 100px;
    }

  }
  .right{
    width: 50%;
    background-color: #39394D;
    font-size: 32px;
    text-align: center;
    line-height: 100px;
    color: #f6d0ab;
  }
}
.centerbox_dialog {
  margin-top: 10px ;
  text-align: center;
  font-size: 30px;
}
.gocer {
  position: relative;
  font-size: 32px;
  //padding: 20px 0;
  margin-top: 30px;
  border-top: 1px solid #ccc;
  color: #ffb606;
  text-align: center;
}
.redBorder{
  background: #FFE6E6 !important;
  color: #F52621 !important;
}
.yellowBorder{
  color: $YellowFontColor !important;
  border: 1px solid $YellowFontColor !important;
}
.greenBorder{
  border: 1px solid #02AD3D !important;
  color: #02AD3D !important;
}
.commonBorder {
  width: 160px;
  height: 56px;
  line-height: 56px;
  font-size: 24px;
  text-align: center;
  border-radius: 6px;
  margin-top: -8px;
}
.blueBorder{
  background: #E6F4FF !important;
  color: #33A5FF !important;

}
.ashBorder{
  //background: #F1F3F7;
  color: $YellowFontCancelColor;
  border: 1px solid $YellowFontCancelColor;

}
</style>
